<style>

</style>
<script>
import { VclFacebook } from 'vue-content-loading';

import {AccountStatus} from '@/enums';
import CopyableText from "@/components/CopyableText";
import {mapGetters} from "vuex";

export default {
  props: ['cftools_id', 'restricted'],
  components: {
    // eslint-disable-next-line vue/no-unused-components
    CopyableText,
    VclFacebook
  },
  computed: {
    ...mapGetters(['getDTLocale']),
  },
  methods: {
    isSteamMaintenancePeriod() {

    },
    handleError: function(error) {
      console.log(`[ERROR] ${error}`);
      this.error = true;
    },
    getData: function() {
      fetch(process.env.VUE_APP_ROOT_API + `v1/profile/${this.cftools_id}/steam`, {credentials: 'include'})
        .then(response => {
          if(response.ok){
            return response.json();
          } else {
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
          }
        })
        .then(data => {
          this.ready = true;
          if(!data.status) {
            this.border = 'offline';
            this.errorCode = data.error;
            this.$emit('overridePersona', null, null, true);
          } else {
            this.profile = data.profile;
            this.bans = data.bans;
            this.steam64 = data.steam64;
            if (data.profile.personastate > 1) this.border = 'online'
            else this.border = 'offline';
            this.$emit('overridePersona', this.profile.personaname, this.profile.avatarfull, false);
          }

        })
        .catch(error => {
          this.handleError(error);
        });
    }
  },
  mounted() {
    this.getData();
  },
  data() {
    return {
      AccountStatus: AccountStatus,
      ready: false,
      error: false,
      errorCode: false,
      steam64: null,
      profile: null,
      bans: null,
      border: null
    }
  }
};
</script>

<template>
  <div>
    <template v-if="ready">
      <div v-if="!steam64">
        <div class="media">
          <div class="mr-3" style="float: left;">
            <div class="d-block">
              <img
                  src="https://cdn.cftools.de/cloud/steam_default.jpg"
                  alt="profile image"
                  class="avatar-sm img-thumbnail border-danger"
                  id="steamProfileAvatar"
              >
              <b-tooltip variant="danger" target="steamProfileAvatar">
                Error
              </b-tooltip>
            </div>
          </div>
          <div class="media-body">
            <div class="media">
              <div class="media-body">
                <div class="text-muted">
                  <template v-if="errorCode === 'steam-unavailable'">
                    <h5 class="mb-1">
                      <i class="far fa-exclamation-triangle text-warning"></i>
                      <span class="ml-1">
                        {{ $t('profile.errors.steam.maintenance.title') }}
                      </span>
                    </h5>
                    <p class="mb-0">
                      <span class="badge badge-warning text-black font-size-11">
                        {{ $t('profile.errors.steam.maintenance.text') }}
                      </span>
                    </p>
                  </template>
                  <template v-else-if="errorCode === 'profile-unavailable'">
                    <h5 class="mb-1">
                      <i class="far fa-exclamation-triangle text-danger"></i>
                        <span class="ml-1">
                        {{ $t('profile.errors.steam.deleted.title') }}
                      </span>
                    </h5>
                    <p class="mb-0">
                      <span class="badge badge-danger text-black font-size-11">
                        {{ $t('profile.errors.steam.deleted.text') }}
                      </span>
                    </p>
                  </template>
                  <template v-else>
                    <i class="far fa-exclamation-triangle text-danger"></i>
                    <span class="ml-1">
                      Steam Gateway returned an unexpected response
                    </span>
                  </template>

                </div>
              </div>
            </div>
            <hr>
            <div class="row">
              <div class="col">
                <div>
                  <p class="text-muted text-truncate mb-2">
                    {{ $t('profile.steam.ban.game.title') }}
                  </p>
                  <h6 class="mb-0">
                    <i class="far fa-shield-alt text-warning"></i>
                    <template v-if="errorCode === 'steam-unavailable'">
                      {{ $t('profile.errors.steam.maintenance.title') }}
                    </template>
                    <template v-else-if="errorCode === 'profile-unavailable'">
                      {{ $t('profile.errors.steam.deleted.title') }}
                    </template>
                    <template v-else>
                      Steam Gateway returned an unexpected response
                    </template>
                  </h6>
                </div>
              </div>
              <div class="col">
                <div>
                  <p class="text-muted text-truncate mb-2">
                    {{ $t('profile.steam.ban.vac.title') }}
                  </p>
                  <h6 class="mb-0">
                    <i class="far fa-shield-alt text-warning"></i>
                    <template v-if="errorCode === 'steam-unavailable'">
                      {{ $t('profile.errors.steam.maintenance.title') }}
                    </template>
                    <template v-else-if="errorCode === 'profile-unavailable'">
                      {{ $t('profile.errors.steam.deleted.title') }}
                    </template>
                    <template v-else>
                      Steam Gateway returned an unexpected response
                    </template>
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="media">
          <div class="mr-3" style="float: left;">
            <div class="d-block">
              <img
                  :src="profile.avatarfull"
                  :class="{'border-primary': (border === 'online'), 'border-danger': (border === 'offline')}"
                  alt="profile image"
                  class="avatar-sm img-thumbnail"
                  id="steamProfileAvatar"
              >
              <b-tooltip :variant="(border === 'online') ? 'online' : 'danger'" target="steamProfileAvatar">
                <template v-if="border === 'online'">
                  Online
                </template>
                <template v-else>
                  Offline
                </template>
              </b-tooltip>
            </div>
          </div>
          <div class="media-body">
            <div class="media">
              <div class="media-body">
                <div class="text-muted">
                  <h5 class="mb-1">
                    <i class="fab fa-steam"></i>
                    <span class="ml-1">
                      {{ profile.personaname }}
                    </span>
                  </h5>
                  <p class="mb-0">
                    <!-- No community profile -->
                    <template v-if="profile.profilestate !== 1">
                      <span class="badge badge-danger">
                        <i class="far fa-exclamation-triangle font-size-12"></i>
                        {{ $t('profile.errors.steam.noprofile') }}
                      </span>
                    </template>
                    <template v-else>
                      <!-- Public profile -->
                      <template v-if="profile.communityvisibilitystate === 3">
                        {{ $t('profile.steam.registered') }}
                        <b>
                          {{ $d(new Date(profile.timecreated * 1000), 'date', getDTLocale()) }}
                        </b>
                      </template>
                      <!-- Private profile -->
                      <template v-else>
                        <span class="badge badge-warning font-size-12 text-light">
                          <i class="far fa-exclamation-triangle"></i>
                          {{ $t('profile.errors.steam.private') }}
                        </span>
                      </template>
                    </template>
                  </p>
                </div>
              </div>
              <a :href="'https://steamcommunity.com/profiles/' + steam64" target="_blank">
                <button class="btn btn-light btn-sm">
                  <i class="fal fa-external-link-alt mr-1"></i>
                  {{ $t('profile.steam.hotlink') }}
                </button>
              </a>
            </div>
            <hr>
            <div class="row" v-if="!restricted">
              <div class="col">
                <div>
                  <p class="text-muted text-truncate mb-2">
                    {{ $t('profile.steam.ban.game.title') }}
                  </p>
                  <h6 class="mb-0">
                    <template v-if="bans.NumberOfGameBans">
                      <i class="far fa-shield-alt text-danger"></i>
                      <span class="ml-1">
                        <b class="text-danger">{{ bans.NumberOfGameBans }}</b>
                        {{ $t('profile.steam.ban.label') }}
                      </span>
                    </template>
                    <template v-else>
                      <i class="far fa-shield-alt text-success"></i>
                      {{ $t('profile.steam.ban.game.none') }}
                    </template>
                  </h6>
                </div>
              </div>
              <div class="col">
                <div>
                  <p class="text-muted text-truncate mb-2">
                    {{ $t('profile.steam.ban.vac.title') }}
                  </p>
                  <h6 class="mb-0">
                    <template v-if="bans.NumberOfVACBans">
                      <i class="far fa-shield-alt text-danger"></i>
                      <span class="ml-1">
                        <b class="text-danger">{{ bans.NumberOfVACBans }}</b>
                        {{ $t('profile.steam.ban.label') }}
                      </span>
                    </template>
                    <template v-else>
                      <i class="far fa-shield-alt text-success"></i>
                      {{ $t('profile.steam.ban.vac.none') }}
                    </template>
                  </h6>
                </div>
              </div>
              <template v-if="bans.NumberOfGameBans || bans.NumberOfVACBans">
                <div class="col">
                  <p class="text-muted text-truncate mb-2">
                    {{ $t('profile.steam.ban.last.title') }}
                  </p>
                  <h6 class="mb-0">
                    <b class="text-danger">
                      {{ bans.DaysSinceLastBan }}
                    </b>
                    {{ $t('profile.steam.ban.last.ago') }}
                  </h6>
                </div>
              </template>
            </div>
            <div class="row" v-else>
              <div class="col">
                <div class="blur-content">
                  <p class="text-muted text-truncate mb-2">
                    {{ $t('profile.steam.ban.game.title') }}
                  </p>
                  <h6 class="mb-0">
                    <i class="far fa-shield-alt"></i>
                    Lorem ipsum dolor
                  </h6>
                </div>
                <div class="blur-content-text h6 text-center text-danger shadow-lg">
                  {{$t('profile.free_limited')}}
                </div>
              </div>
              <div class="col">
                <div class="blur-content">
                  <p class="text-muted text-truncate mb-2">
                    {{ $t('profile.steam.ban.vac.title') }}
                  </p>
                  <h6 class="mb-0">
                    <i class="far fa-shield-alt "></i>
                    sadipscing elitr
                  </h6>
                </div>
                <div class="blur-content-text h6 text-center text-danger shadow-lg">
                  {{$t('profile.free_limited')}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else-if="error">
      <div class="row">
        <div class="col-lg-12 col-sm-12">
          <div class="card border border-danger">
            <div class="card-header bg-transparent border-danger">
              <h5 class="my-0 text-danger">
                <i class="far fa-exclamation-circle mr-3"></i>
                {{$t('error.server.generic.title')}}
              </h5>
            </div>
            <div class="card-body pt-0">
              <h5 class="card-title mt-0"> {{$t('error.server.generic.component')}}</h5>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="text-center">
        <VclFacebook :speed="1" primary="#FFFFFF" secondary="#bdbdbd"></VclFacebook>
      </div>
    </template>
  </div>
</template>
